import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Modal({ children, isOpen, setOpen, maxWidth = "2xl" }) {
    const close = () => {
        setOpen(false);
    };

    const maxWidthClass = {
        sm: "sm:max-w-sm",
        md: "sm:max-w-md",
        lg: "sm:max-w-lg",
        xl: "sm:max-w-xl",
        "2xl": "sm:max-w-2xl",
    }[maxWidth];

    return (
        <Transition show={isOpen} as={Fragment} leave="duration-200">
            <Dialog
                as="div"
                className="fixed inset-0 flex overflow-y-auto px-4 py-6 sm:px-0 items-center z-1000 transform transition-all"
                onClose={close}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="absolute inset-0 bg-gray-500/75" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <Dialog.Panel
                        className={`bg-amber-50 text-sky-900 overflow-hidden shadow-xl sm:rounded-3xl mb-6 p-6 rounded-lg transform transition-all sm:w-full sm:mx-auto ${maxWidthClass}`}
                    >
                        <button
                            type="button"
                            className="absolute top-0 right-0 mt-4 mr-4 text-2xl"
                            onClick={close}
                        >
                            &times;
                        </button>
                        {children}
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}
